label {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  margin-bottom: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.navbar-nav .NavbarShortcuts {
  display: flex;
  align-items: center;
}
.gap-1 {
  gap: 7px;
}
.TransactionHeaderCard {
  background-color: #d2e0fb;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
}
.form-control {
  font-size: 1rem;
  color: #000;
}
.form-control:focus {
  color: #000;
}
.form-control:hover {
  color: #000;
}
.form-control::placeholder {
  color: #b3b9bd;
}
.TableIconsContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.SalesFormNewControl.disabled {
  background-color: #eee;
  border-color: #eee;
}
.SalesFormNewControl {
  padding: 0 5px;
  text-align: left;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: #dae3f3;
  border-radius: 0px;
  color: #272c34;
  width: 70%;
  cursor: text;
  font-size: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  height: 30px;
}
.SalesFormNewControl:focus {
  border: 1px solid #fe634e;
}
/* styling for quotation modal */
.Link {
  color: #3183ec;
  cursor: pointer;
}
.Link:hover {
  color: #3183ec;
  text-decoration: underline;
}
p.CustomizedPara {
  margin-bottom: 0;
  font-weight: 600;
  color: #000;
}
.SalesFormTableResponsive {
  max-height: calc(100vh - 19rem);
  /* margin-bottom: 8rem; */
}
th.FixedTH {
  position: fixed;
  background: #fff;
}
.SalesFormTable thead th strong {
  font-size: 13px;
  padding: 0px 3px;
}
.table.SalesFormTable th {
  padding: 0;
  text-align: center;
}
.SalesFormTable tbody td {
  border-color: #eee;
  padding: 1px 1px;
}
.row.DeliveryChallanCard {
  height: 44rem;
}

.SalesFooter {
  background-color: #d2e0fb;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.disabled {
  background-color: #eee;
  border-color: #eee;
}
.TableIcons {
  font-size: 1.5rem;
  color: #0173a8;
}
.TableIcons.fa-edit {
  margin-top: 2px;
}
.TableIcons:hover {
  cursor: pointer;
  color: #000;
}
.CursorNA {
  cursor: not-allowed;
}
label.required::after {
  content: " *";
  color: red;
}
p.required::after {
  content: " *";
  color: red;
}
th strong.required::after {
  content: " *";
  color: red;
}
.SoulsoftLogo {
  height: 80px;
  width: 60px;
  object-fit: contain;
  margin-right: 5px;
}
.SoulsoftLogoTxt {
  height: 100px;
  width: 200px;
  object-fit: contain;
}
.TextSoulsoft {
  color: #0073a8;
}
.TextBlack {
  color: #000;
}

/* Stylilng for Print pages */
.PrintPage {
  padding: 5% 3%;
  margin: 0% 10%;
  max-width: 100rem;
  min-width: fit-content;
  margin: auto;
}
.PrintLogo {
  height: 100px;
  width: 100%;
  object-fit: contain;
}

.PrintTable {
  color: black;
  width: 100%;
}
.PrintTable thead th strong {
  font-size: 13px;
  padding: 0px 3px;
}
.PrintTable th {
  background-color: #0173a8;
  color: #fff;
  border: 1px solid #eee;
  padding: 5px;
}
.PrintTable tbody td {
  border: 1px solid black;
  border-color: #eee;
  padding: 5px;
}

@media (max-width: 1281px) {
  .SalesFormNewControl {
    height: 21px;
    font-size: 13px;
  }
  p.CustomizedPara {
    font-size: 13px;
    line-height: 1.4;
  }
  .SalesCustomerRowInside .CustomizedPara {
    width: 44%;
  }
}

@media (max-width: 768px) {
  .navbar-nav .NavbarShortcuts {
    display: none;
  }
  .SalesFooter {
    position: static;
  }
}

@media print {
  body {
    background-color: white;
    font-family: "poppins", sans-serif;
  }
  .PrintPage .card {
    box-shadow: none;
  }
  @page {
    margin: 20px;
    margin-top: 20px;
  }
}
